function DataActive() {
    var me = this;
    var views = { ...viewPack1, ...viewPack2, ...viewPack3, ...viewPack4 }
    var viewsCache = views;
    var configs = {};
    this.$head = null;
    this.$body = null;
    this.allForms;
    this.dir = 'ltr';
    this.hooks = new Hook();

    this.pageReload = function () {
        location.reload();
    }

    this.showErrors = function (text) {
        swal({
            text,
            dangerMode: true,
            icon: "error",
        });
    }

    this.getRoute = function (uri) {
        let target = {};
        for (let routeName in routeList) {
            let pattern = routeList[routeName].uri;
            target = routeList[routeName];
            target.name = routeName;
            if (routeName == uri) {
                return target;
            }
            if (pattern == uri) {
                if (target.redirectTo != undefined) {
                    routeName = target.redirectTo;
                    target = routeList[routeName];
                    target.name = routeName;
                }
                return target;
            }
            var variables = pattern.match(/\{([^\}]+)\}/g);
            if ($.isEmptyObject(variables)) {
                continue;
            }
            target.params = {};
            pattern = pattern.replace(/\{([^\}]+)\}/g, '([^\/]+)').replace(/\//g, '\\/');
            let matched = uri.match(new RegExp('^' + pattern + '$'));
            if (matched === null || matched.length - 1 != variables.length) {
                continue;
            }
            variables.forEach((item, index) => {
                target.params[item.replace(/[\{\}]+/g, '')] = matched[index + 1];
            });
            return target;
        }
        return target;
    }

    this.pageRoute = function (address, params = {}) {
        params.lang = dataActive.location.query.lang;
        var queryString, uri;
        [uri, queryString] = address.split('?');
        params = $.extend(params, this.parseQueryString(queryString || ''));
        let route = this.getRoute(uri);
        if (route.name == uri) {
            uri = routeList[route.name].uri;
        }
        var beforeHook = this.hooks.call('beforePageRoute', { address: routeList[route.name].name, params: params, title: trans(routeList[route.name].title) });
        address = beforeHook.address || address;
        params = beforeHook.params || params;
        var queryString = this.createQueryString(params);
        if (queryString.length > 0) {
            queryString = "?" + queryString;
        }

        history.pushState(beforeHook, '', uri + decodeURIComponent(queryString));
        this.parseLocation();
        this.$body.html(this.view(route.path));
        this.trackAllViews();
        lug.showPid();
        this.trackAllProcess();
        this.trackAllForms();
        this.hooks.call('afterPageRoute', { address: address, params: params });
    };

    window.onpopstate = function (event) {
        var beforeHook = me.hooks.call('beforePageRoute', event.state);
        me.parseLocation();
        me.$body.html(me.view(routeList[beforeHook.address].path || beforeHook.address));
        try {
            me.trackAllViews();
            lug.showPid();
            me.trackAllProcess();
            me.trackAllForms();
        } catch (ex) {
            me.hooks.call('onPopStateError', ex)
        }
        me.hooks.call('afterPageRoute', event.state);
    };

    this.trackAllForms = function () {
        this.preventDefaultEvent();
        me.allForms = {};
        $('form').each(function (index, form) {
            var $form = $(form);
            if ($form.data("name") == undefined) { return; }
            var formName = $form.data("name") + "Form";
            var tracker = dataActive.getObject(formName, $form);
            me.allForms[formName] = tracker;
            $form.find('[type=submit]').attr('type', 'button').on('click', function () {
                tracker.disableButton();
                let ajaxParams = tracker.ajaxParams();
                setStorage("ajaxParams", ajaxParams);
                ajax(ajaxParams);
            });
        });
    }

    this.trackAllProcess = function () {
        $("[data-process]").each(function (index, process) {
            var $process = $(process);
            $process.processTracker = new processTracker($process);
        });
    }

    this.trackAllViews = function (element) {
        $(document || element).find('u[data-view]').each(function (index, element) {
            var $element = $(element);
            $element.before(view($element.data('view'), $element.data('variables'))).remove();
        });
    };

    this.getObject = function (className, params) {
        if (typeof window[className] !== "function") {
            throw new Error(className + " not found!");
        }
        if (params == undefined) {
            return new window[className]();
        }
        if (Array.isArray(params)) {
            throw new Error("multiparams not implemented");
        }
        return new window[className](params);
    }

    this.createQueryString = function (params) {
        var keys = Object.keys(params);
        keys = keys.map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]));
        return keys.join("&");
    }

    this.checkLogin = function () {
        window.addEventListener('message', function (event) {
            var userToken = '';
            var loginInformation = JSON.parse(localStorage.getItem('loginInformation' + Constants.ver)) || {};
            if (!$.isEmptyObject(loginInformation) && loginInformation.token !== "") {
                var check = false;
                ajax({
                    url: dataActive.location.marketplace + "/v1/checkuserlogin",
                    headers: { 'Authorization': 'Bearer ' + loginInformation.token },
                    method: "get",
                    async: false,
                    success: function (data) {
                        check = data.status;
                    },
                    error: function () { check = false; }
                });
                if (check) {
                    userToken = loginInformation.token;
                } else {
                    localStorage.removeItem("loginInformation" + Constants.ver);
                }
            }
            if (event.origin == dataActive.location.accounts) {
                event.source.postMessage(userToken, event.origin)
            }

        }, false);
        var parentWindow = window.parent;
        /* SHAKE HAND WITH PARENT */
        window.addEventListener("load", () => {
            parentWindow.postMessage("shakehand", dataActive.location.accounts);
        });
    }

    this.location = {};
    this.lastLocation = {};
    this.parseLocation = function () {
        this.lastLocation = getStorage("lastLocation") || {};
        var route = this.getRoute(location.pathname) || 'index';
        route.middlewares = route.middlewares || [];
        var queryString = location.search.substr(1);
        queryString = queryString.length ? this.parseQueryString(queryString) : {}
        queryString.lang = queryString.lang || '';
        this.location = {
            route: route,
            host: location.host,
            protocol: location.protocol,
            path: location.pathname,
            query: queryString,
            routeName: route.name || null,
            origin: location.origin,
            marketplace: location.origin.replace(location.host, "marketplace." + location.host),
            accounts: location.origin.replace(location.host, "accounts." + location.host),
            address: location.href.replace(location.origin, '')
        };
        route.middlewares.forEach(item => window[item]());
        switch (route.name) {
            case 'loginPage':
            case 'changePassword':
            case 'resetPassword':
            case 'logout':
                return true;
            case 'checkLogin':
                this.checkLogin();
                return;
            default:
                setStorage("lastLocation", this.location);
                break;
        }

        return true;
    }

    this.previous = function () {
        if ($.isEmptyObject(this.lastLocation)) {
            return dataActive.pageRoute("index");
        }
        dataActive.pageRoute(this.lastLocation.address, this.lastLocation.query);
    }

    this.parseQueryString = function (query) {
        var result = {};
        query.split("&").map(function (item) {
            item = item.split("=");
            result[item[0]] = item[1];
        });
        return result;
    }

    this.use = function (scriptName, after) {
        if (scriptName.match(/https?:\/\//i) === null) {
            scriptName = `/js/${scriptName}.js`;
        }
        var script = document.createElement('script');
        script.setAttribute('src', scriptName);
        script.setAttribute("data-scriptName", scriptName);
        script.onload = function (e) {
            var scriptName = e.target.getAttribute("data-scriptName");
            if (after !== undefined) {
                after();
            }
        };
        document.body.append(script);
        script.remove();
    }

    this.view = function (viewName, variables) {
        var variables = variables || {};
        if (variables != undefined && !$.isEmptyObject(variables)) {
            for (let index in variables) {
                try {
                    eval(`var ${index}=JSON.parse('${JSON.stringify(variables[index]).replace(/['\\"]/g, '\\$&')}')`);
                } catch (e) {
                    this.hooks.call('onViewError', { e })
                }
            }
        }

        if (viewName.match(/https?:\/\//i)) {
            ajax({
                url: viewName,
                async: false,
                xhr: function () {
                    var xhr = $.ajaxSettings.xhr();
                    var setRequestHeader = xhr.setRequestHeader;
                    xhr.setRequestHeader = function (name, value) {
                        if (name == 'pid') return;
                        setRequestHeader.call(this, name, value);
                    }
                    return xhr;
                },
                success: (text) => rawText = text,
            });
            return rawText;
        }

        var rawText = viewsCache[viewName] || '';
        if (rawText == '') {
            throw new Error('Invalid view name: ' + viewName);
        }
        return eval('`' + rawText + '`');
    }

    this.loadConfigs = function () {
        ajax({
            url: dataActive.location.marketplace + "/v1/node",
            method: "get",
            async: false,
            data: {},
            success: function (data) {
                data.currentCurrency = getStorage('siteConfig').currentCurrency || data.currency;
                dataActive.location.query.lang = dataActive.location.query.lang || data.language.iso_code;
                data.currentCalendar = getStorage('siteConfig').currentCalendar || ((dataActive.location.query.lang == 'FA') ? 'jalali' : 'gregorian');
                setStorage("siteConfig", data);
                configs = data;

                var searcherIdentity = localStorage.getItem('searcherIdentity') || data.searcherIdentity;
                localStorage.setItem('searcherIdentity', searcherIdentity);
            },
            error: function (data) {
                if (data.status == 503) {
                    routeList['underConstructionPage'] = { title: "Under Construction", uri: "/under-construction", path: 'pages/under-construction', middlewares: [] };
                    me.pageRoute('underConstructionPage');
                    return;
                }
                woops();
            },
        });
    }


    this.getConfig = function (key, defaultValue) {
        return configs[key] || defaultValue || null;
    }

    this.updateConfig = function (key, value) {
        configs[key] = value;
        setStorage('siteConfig', configs);
    }

    this.fileUrl = function (file, imageVersion = 'file') {
        if ($.isEmptyObject(file) || file == "-") {
            return getConfig('fileCenterDomain') + "/files/noImage/noImage/file.png";
        }
        if (file.match(/https?:\/\//i) !== null) {
            return file;
        }
        if (file.match(/files/i) !== null) {
            return getConfig('fileCenterDomain') + file
        }
        var fileUrl;
        var jsonFile;
        if (!$.isEmptyObject(localStorage.getItem(file))) {
            jsonFile = JSON.parse(localStorage.getItem(file));
        } else {
            jsonFile = me.getJsonInfoFile(file);
        }
        if (!$.isEmptyObject(jsonFile)) {
            var extension = (jsonFile['extension'] !== undefined) ? jsonFile['extension'] : ((jsonFile['originalFileName'].split('.'))[1]);
            var fileMime = jsonFile.mime;
            if (fileMime.includes('image')) {
                if (!$.isEmptyObject(jsonFile['versions'])) {
                    fileUrl = `${getConfig('fileCenterDomain')}/files/${file}/${imageVersion}.${extension}`;
                } else {
                    fileUrl = `${getConfig('fileCenterDomain')}/files/${file}/${jsonFile['fileName']}`;
                }
            } else if (fileMime.includes('video')) {
                fileUrl = `${getConfig('fileCenterDomain')}/files/${file}/${jsonFile['fileName']}`;
            } else if (fileMime.includes('pdf')) {
                fileUrl = getConfig('fileCenterDomain') + "/files/noImage/noImage/pdf.png";
            } else if (fileMime.includes('sheet')) {
                fileUrl = getConfig('fileCenterDomain') + "/files/noImage/noImage/xls.png";
            } else if (fileMime.includes('doc') || fileMime.includes('docx')) {
                fileUrl = getConfig('fileCenterDomain') + "/files/noImage/noImage/docx.png";
            } else {
                fileUrl = getConfig('fileCenterDomain') + "/files/noImage/noImage/attachment.png";
            }
            return fileUrl;
        } else {
            return getConfig('fileCenterDomain') + "/files/noImage/noImage/file.png";
        }
    }

    this.downloadFile = function (file, imageVersion = 'file') {
        if ($.isEmptyObject(file) || file == "-") {
            return getConfig('fileCenterDomain') + "/files/noImage/noImage/file.png";
        }
        if (file.match(/https?:\/\//i) !== null) {
            return file;
        }
        if (file.match(/files/i) !== null) {
            return getConfig('fileCenterDomain') + file
        }
        var fileUrl;
        var jsonFile;
        if (!$.isEmptyObject(localStorage.getItem(file))) {
            jsonFile = JSON.parse(localStorage.getItem(file));
        } else {
            jsonFile = me.getJsonInfoFile(file);
        }
        if (!$.isEmptyObject(jsonFile)) {
            var extension = (jsonFile['extension'] !== undefined) ? jsonFile['extension'] : ((jsonFile['originalFileName'].split('.'))[1]);
            if (!$.isEmptyObject(jsonFile['versions'])) {
                fileUrl = `${getConfig('fileCenterDomain')}/files/${file}/${imageVersion}.${extension}`;
            } else {
                fileUrl = `${getConfig('fileCenterDomain')}/files/${file}/${jsonFile['fileName']}`;
            }
            return fileUrl;
        } else {
            return getConfig('fileCenterDomain') + "/files/noImage/noImage/file.png";
        }
    }

    this.getJsonInfoFile = function (token) {
        var file;
        $.ajax({
            dataType: "json",
            url: getConfig('fileCenterDomain') + '/files/' + token + '/info.json',
            async: false,
            xhr: function () {
                var xhr = $.ajaxSettings.xhr();
                var setRequestHeader = xhr.setRequestHeader;
                xhr.setRequestHeader = function (name, value) {
                    if (name == 'pid') return;
                    setRequestHeader.call(this, name, value);
                }
                return xhr;
            },
            success: function (data) {
                file = data;
                localStorage.setItem(token, JSON.stringify(data));
            }
        });

        return file;
    }

    this.defaultAjaxError = function (data, validation) {
        var errortext = "";
        switch (data.status) {
            case 422:
                swal({
                    text: typeof validation == 'function' ? validation(data.responseJSON) : me.defaultValidation(data.responseJSON),
                    icon: "error",
                    dangerMode: true,
                });
                return;
            case 460:
                errortext = trans(data.responseJSON.message);
                break;
            case 470:
                errortext = trans(data.responseJSON.message);
                swal({
                    text: errortext,
                    icon: "error",
                    dangerMode: true,
                });
                return;
            default:
                this.hooks.call('onDefaultAjaxError', { data });
                errortext = trans("Error happened");
                break;
        }
        swal({
            text: errortext,
            icon: "error",
            dangerMode: true,
        }).then(() => me.hooks.call('afterAjaxError', data));
    }

    this.defaultValidation = function (errors) {
        var errortext = "";
        for (var field in errors) {
            let message = (errors[field] instanceof Array) ? errors[field].map(trans) : trans(errors[field]);
            errortext = errortext.concat(message + "\n").replace(/#field/g, trans(field))
        }
        return errortext;
    }

    this.ajax = function (configs) {
        configs = this.hooks.call('beforeAjax', configs);
        $.ajax(configs);
    }

    this.trans = function (key) {
        //TODO: lang optimization for multi language
        let lang = window.lang || {};
        let items;
        if (key == undefined) {
            return key;
        }
        [key, items] = key.split('!');
        key = lang[key] || key;
        if (items === undefined) {
            return key;
        }
        items = items.split(',');
        items.forEach((item, index) => {
            index = '@' + (index + 1);
            key = key.replace(index, trans(item));
        });
        return key;
    }

    this.siteLanguage = function () {
        $.ajax({
            url: fileUrl(getConfig('langFolder') + '/' + (dataActive.location.query.lang + '.json?ver=' + Math.random()).toLowerCase()),
            async: false,
            header: {},
            success: function (data) {
                window.lang = data;
            },
            error: function () {
                window.lang = {};
            }
        });
        var $html = $("html");
        if (dataActive.location.query.lang == 'FA') {
            me.dir = "rtl";
            dataActive.$head.find('.ar-style').remove();
            var faCss = dataActive.$head.find('.stylesheet').data('fa');
            dataActive.$head.append(`<link rel="stylesheet" class="fa-style" href="${faCss}" />`);
        } else if (dataActive.location.query.lang == 'AR') {
            me.dir = "rtl";
            dataActive.$head.find('.fa-style').remove();
            var arCss = dataActive.$head.find('.stylesheet').data('ar');
            dataActive.$head.append(`<link rel="stylesheet" class="ar-style" href="${arCss}" />`);
        } else {
            me.dir = "ltr";
            dataActive.$head.find('.fa-style').remove();
            dataActive.$head.find('.ar-style').remove();
        }
        if (dataActive.location.query.lang == 'FA') {
            DateFormat.calendar = 'jalali';
            DateConvert.calendar = 'jalali';
            ChooseCalendar.calendar = 'jalali';
            MonthPicker.calendar = 'jalali';
            dayjs.locale('fa');
        } else {
            DateFormat.calendar = 'gregorian';
            DateConvert.calendar = 'gregorian';
            ChooseCalendar.calendar = 'gregorian';
            MonthPicker.calendar = 'gregorian';
            dayjs.locale('en');
        }
        $html.attr("lang", dataActive.location.query.lang.toLowerCase());
        $html.attr("dir", me.dir);
    }

    this.currencyFormatter = function (amount, currency) {
        let defaultCurrency = currency || (getStorage('siteConfig').currentCurrency);
        var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: defaultCurrency.abb,
            currencyDisplay: 'code',
            maximumFractionDigits: defaultCurrency.decimal_places,
            minimumFractionDigits: defaultCurrency.decimal_places,

        });

        return formatter.format(amount).replace(/\b[a-zA-Z]{3}\b/gi, '').trim();
    }

    this.preventDefaultEvent = function () {
        $(window).keydown(function (event) {
            if ((event.keyCode == 13) && ($(event.target)[0]['nodeName'] != 'TEXTAREA')) {
                event.preventDefault();
                return false;
            }
        });
    }

}

